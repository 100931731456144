import React from "react";
import ProjectView from "../../views/project-view";
import { graphql } from 'gatsby'    

const ForschungProject = (props) => {
  const project = props.data.forschung
  const imgData = props.data.allFile
  const projects = props.data.allForschung && props.data.allForschung.nodes
  const location = props.location
  const categoryArray = {
    1: projects
  }
    return(
      <ProjectView project={project} projects={projects} images={imgData} location={location} categoryArray={categoryArray} />
    )
  }

export default ForschungProject


export const query = graphql`
query ($id: String) {
  forschung(id: { eq: $id }) {
      title
      category
      slug
      _id
      city
      year
      description1
      images {
        alt_1
        alt_2
        alt_3
        alt_4
        alt_5
        alt_6
        alt_7
        alt_8
        alt_9
        alt_10
        alt_11
        alt_12
        alt_13
      }
      files {
        url
        text1
        text2
      }
      links {
        url
        text1
        text2
      }
      gatsbyPath(filePath: "/forschung/{Forschung.slug}")
    }

    allFile(
      filter: {extension: {regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)|(avif)/"},
        name: {regex: "/[a-zA-Z0-9][_][0-9]/"},
      relativePath:{regex: "/(forschung)/"}}
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            outputPixelDensities: [0.25, 0.5, 0.75, 1, 2]
          )
        }
        relativePath
      }
    }
    allForschung {
      nodes{
        _id
        slug
        title
        category
      }
    }
  }
`